<template>
  <b-button
    class="btn-icon"
    pill
    variant="flat-secondary"
    :to="{name: 'cabinet.chat'}"
  >
    <feather-icon
      size="21"
      class="text-body"
      icon="MessageSquareIcon"
    />
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Messages',
  components: {
    BButton,
  },
  computed: {
    ...mapGetters({
      listeningForChat: 'websockets/get_listening_for_chat',
    }),
  },
  beforeDestroy() {
    this.disconnectFromWebsockets()
  },
  mounted() {
    // this.listenForMessages(message => {
    //   if (!this.listeningForChat) {
    //     console.log(message)
    //   }
    // })
  },
  methods: {
    ...mapActions({
      listenForMessages: 'websockets/listenForMessages',
      disconnectFromWebsockets: 'websockets/disconnect',
    }),
  },
}
</script>

<style scoped>

</style>
