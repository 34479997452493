import {
  ref, computed,
} from '@vue/composition-api'
import store from '@/store'

export default function useVerticalLayout(currentRoute, navbarType, footerType) {
  const onlyOverlayMenu = ref(false)
  const tempNavbarType = ref(navbarType.value)
  const tempFooterType = ref(footerType.value)

  const isStreamRoute = routeName => ['cabinet.sessions.online', 'cabinet.room'].includes(routeName)

  const isVerticalMenuActive = ref(false)
  const isNavbarHidden = ref(isStreamRoute(currentRoute))
  const isFooterHidden = ref(isStreamRoute(currentRoute))

  const toggleVerticalMenuActive = () => {
    isVerticalMenuActive.value = !isVerticalMenuActive.value
  }

  const currentBreakpoint = ref('xl')

  const isVerticalMenuCollapsed = computed(() => store.state.verticalMenu.isVerticalMenuCollapsed)

  const layoutClasses = computed(() => {
    const classes = []

    if (currentBreakpoint.value === 'xl' && !onlyOverlayMenu.value) {
      classes.push('vertical-menu-modern')
      classes.push(isVerticalMenuCollapsed.value ? 'menu-collapsed' : 'menu-expanded')
    } else {
      classes.push('vertical-overlay-menu')
      classes.push(isVerticalMenuActive.value ? 'menu-open' : 'menu-hide')
    }

    // Navbar
    if (isNavbarHidden.value) tempNavbarType.value = 'hidden'
    else tempNavbarType.value = navbarType.value

    classes.push(`navbar-${tempNavbarType.value}`)

    // Footer
    if (isFooterHidden.value) tempFooterType.value = 'hidden'
    else tempFooterType.value = footerType.value

    if (tempFooterType.value === 'sticky') classes.push('footer-fixed')
    if (tempFooterType.value === 'static') classes.push('footer-static')
    if (tempFooterType.value === 'hidden') classes.push('footer-hidden')

    return classes
  })

  // ------------------------------------------------
  // Resize handler for Breakpoint
  // ------------------------------------------------
  const routeChangeHandler = routeName => {
    if (routeName) {
      const isStream = isStreamRoute(routeName)
      if (isStream !== onlyOverlayMenu.value) isVerticalMenuActive.value = false

      setTimeout(() => {
        onlyOverlayMenu.value = isStream
        isNavbarHidden.value = isStream
        isFooterHidden.value = isStream
      }, isStream ? 0 : 500)
    }
  }

  const resizeHandler = () => {
    // ? This closes vertical menu when title bar is shown/hidden in mobile browsers.
    // ? We will watch for breakpoint to overcome this issue
    // isVerticalMenuActive.value = window.innerWidth >= 1200

    // ! You can use store getter to get breakpoint
    if (window.innerWidth >= 1200) currentBreakpoint.value = 'xl'
    else if (window.innerWidth >= 992) currentBreakpoint.value = 'lg'
    else if (window.innerWidth >= 768) currentBreakpoint.value = 'md'
    else if (window.innerWidth >= 576) currentBreakpoint.value = 'sm'
    else currentBreakpoint.value = 'xs'
  }

  const overlayClasses = computed(() => {
    if ((currentBreakpoint.value !== 'xl' || onlyOverlayMenu.value) && isVerticalMenuActive.value) return 'show'
    return null
  })

  const navbarTypeClass = computed(() => {
    if (tempNavbarType.value === 'sticky') return 'fixed-top'
    if (tempNavbarType.value === 'static') return 'navbar-static-top'
    if (tempNavbarType.value === 'hidden') return 'd-none'
    return 'floating-nav'
  })

  const footerTypeClass = computed(() => {
    if (tempFooterType.value === 'static') return 'footer-static'
    if (tempFooterType.value === 'hidden') return 'd-none'
    return ''
  })

  return {
    isVerticalMenuActive,
    toggleVerticalMenuActive,
    isVerticalMenuCollapsed,
    layoutClasses,
    overlayClasses,
    navbarTypeClass,
    footerTypeClass,
    resizeHandler,
    routeChangeHandler,
  }
}
