<template>
  <b-row
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col
          cols="12"
          class="d-flex justify-content-start align-items-center flex-row"
        >
          <h2
            class="content-header-title float-left pr-1 mb-0"
            :class="{'no-bar': !$route.meta.breadcrumb}"
          >
            {{ title }}
          </h2>
          <div
            v-if="$route.meta.breadcrumb"
            class="breadcrumb-wrapper"
          >
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
          <b-button
            v-if="$route.meta.backBtn"
            variant="outline-primary"
            class="mx-2"
            @click="$router.push({name: $route.meta.backBtn.route})"
          >
            {{ $t('components.button.labels.back') }}
          </b-button>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12"
    >
      <b-button
        variant="primary"
        class="btn btn-primary"
        @click="logout"
      >
        <feather-icon icon="LogOutIcon" />
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BButton,
  },
  data: () => ({
    title: null,
  }),
  watch: {
    $route: {
      handler() {
        this.title = this.$route.meta.pageTitle
      },
      deep: true,
    },
  },
  mounted() {
    this.title = this.$route.meta.pageTitle
    this.$eventBus.$on('page-title', title => {
      this.title = title
    })
  },
  methods: {
    ...mapActions({
      removeUser: 'user/removeUser',
    }),
    async logout() {
      useJwt.removeToken()
      useJwt.removeRefreshToken()
      await this.$router.push({ name: 'auth.login' })
      await this.removeUser()
    },
  },
}
</script>
