<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="pl-xl-1 bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <breadcrumbs />
    </div>

    <b-navbar-nav
      class="nav align-items-center ml-auto"
    >
      <template v-if="false">
        <b-button
          variant="primary"
          class="mr-1 btn-icon d-sm-none"
          pill
          size="sm"
          :to="{name: 'cabinet.instruction'}"
        >
          <feather-icon
            icon="HelpCircleIcon"
            size="21"
          />
        </b-button>
        <b-button
          variant="primary"
          class="mr-1 d-none d-sm-block"
          :to="{name: 'cabinet.instruction'}"
        >
          <span>Хочу получать заявки</span>
        </b-button>
      </template>

      <template v-if="!isAdmin">
        <b-button
          variant="flat-warning"
          class="mr-50 btn-icon d-sm-none"
          pill
          size="sm"
          :to="{name: 'cabinet.help'}"
        >
          <feather-icon
            icon="HelpCircleIcon"
            size="21"
          />
        </b-button>
        <b-button
          variant="flat-warning"
          class="mr-50 d-none d-sm-block"
          :to="{name: 'cabinet.help'}"
        >
          <span>Как пользоваться?</span>
        </b-button>
      </template>

      <device-settings-modal v-if="isAdmin">
        <template #activator="{show}">
          <b-button
            variant="flat-secondary"
            class="btn-icon mr-50"
            pill
            @click="show"
          >
            <feather-icon
              icon="SettingsIcon"
              size="19"
              class="d-block"
            />
          </b-button>
        </template>
      </device-settings-modal>

      <!--      <help />-->

      <messages />

      <profile />

      <!--      <dark-Toggler class="d-none d-lg-block" />-->

    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BButton,
} from 'bootstrap-vue'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { mapActions, mapGetters } from 'vuex'
import Profile from '@/layouts/components/navbar/Profile.vue'
import Breadcrumbs from '@/layouts/components/navbar/Breadcrumbs.vue'
// import Help from '@/layouts/components/navbar/Help.vue'
import Messages from '@/layouts/components/navbar/Messages.vue'
import { adminRole, teacherRole, userRoles } from '../../store/user'
import DeviceSettingsModal from '../../components/video-stream/DeviceSettingsModal.vue'

export default {
  components: {
    DeviceSettingsModal,
    Messages,
    // Help,
    Breadcrumbs,
    Profile,
    BLink,
    BNavbarNav,
    BButton,

    // Navbar Components
    // DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    isAdmin() {
      return this.userRole === adminRole
    },
  },
  mounted() {
    if (this.user) this.getSettings()
  },
  methods: {
    ...mapActions({
      getSettings: 'app/getSettings',
    }),
  },
}
</script>
