<template>
  <div>
    <b-breadcrumb class="breadcrumb-slash ">
      <b-breadcrumb-item
        v-for="(route, key) in matched"
        :key="key"
        class="d-flex align-items-center"
        :active="isActive(route)"
        :to="route.path"
      >
        <span class="font-medium-5 font-weight-bold">
          {{ current.name === route.name ? title : route.meta.pageTitle }}
        </span>
      </b-breadcrumb-item>
    </b-breadcrumb>
  </div>
</template>

<script>
import { BBreadcrumb, BBreadcrumbItem } from 'bootstrap-vue'

export default {
  name: 'Breadcrumbs',
  components: { BBreadcrumb, BBreadcrumbItem },
  data() {
    return {
      title: null,
    }
  },
  computed: {
    current() {
      return this.$route
    },
    matched() {
      return this.$route.matched.filter(route => this.isActive(route) || route.meta.pageTitle !== this.current.meta.pageTitle)
    },
  },
  watch: {
    $route() {
      this.title = this.$route.meta.pageTitle
    },
  },
  destroyed() {
    this.$eventBus.$off('page-title')
  },
  mounted() {
    this.title = this.$route.meta.pageTitle
    this.$eventBus.$on('page-title', title => {
      this.title = title
    })
  },
  methods: {
    isActive(route) {
      return this.current.name === route.name
    },
  },
}
</script>

<style scoped>

</style>
