<template>
  <b-nav-item-dropdown
    v-if="user"
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav ml-50">
        <p class="user-name font-weight-bolder mb-0">
          {{ userName }}
        </p>
        <span class="user-status">
          <span>{{ userRole }}</span>
          <!--                  <span> – Баланс {{ balance || 0 }} руб</span>-->
        </span>
      </div>
      <b-avatar
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <store-image
          :image-url="user.image ? user.image.full_url : null"
          avatar
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      :to="{name: 'cabinet.profile'}"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>{{ $t('page.profile.title') }}</span>
    </b-dropdown-item>

    <!--    <b-dropdown-item-->
    <!--      link-class="d-flex align-items-center"-->
    <!--      :to="{name: 'cabinet.subscriptions'}"-->
    <!--    >-->
    <!--      <feather-icon-->
    <!--        size="16"-->
    <!--        icon="ShoppingCartIcon"-->
    <!--        class="mr-50"-->
    <!--      />-->
    <!--      <span>{{ $t('page.subscriptions.title') }}</span>-->
    <!--    </b-dropdown-item>-->

    <b-dropdown-item
      v-if="isTeacher"
      link-class="d-flex align-items-center"
      :to="{name: 'cabinet.help'}"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>Помощь</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      :to="{name: 'cabinet.settings'}"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>{{ $t('page.settings.title') }}</span>
    </b-dropdown-item>

    <dark-toggler dropdown-item />

    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center text-danger"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>{{ $t('page.logout.title') }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import StoreImage from '@/components/store/StoreImage.vue'
import useJwt from '@/auth/jwt/useJwt'
import { teacherRole, userRoles } from '@/store/user'
import { getUserFullName } from '@/helpers'

export default {
  name: 'Profile',
  components: {
    StoreImage,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    DarkToggler,
  },
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      userName: 'user/get_user_full_name',
      balance: 'user/get_balance',
    }),
    userName() {
      return getUserFullName(this.user, { surname: false, patronymic: false })
    },
    userRole() {
      return this.user ? userRoles[this.user.role] : null
    },
    isTeacher() {
      return this.user.role === teacherRole
    },
  },
  mounted() {
    this.getBalance()
  },
  methods: {
    ...mapActions({
      removeUser: 'user/removeUser',
      getBalance: 'user/getBalance',
    }),
    async logout() {
      await useJwt.logout()
      await this.removeUser()
      await this.$router.replace({ name: 'auth.login' })
    },
  },
}
</script>

<style scoped>

</style>
