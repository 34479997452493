import VueI18n from '@/libs/i18n'

export default [
  {
    title: VueI18n.t('page.dashboard.title'),
    route: 'cabinet.dashboard',
    icon: 'HomeIcon',
    resource: 'dashboard',
    action: 'read',
  },
  {
    title: 'Видеозвонок',
    route: 'cabinet.stream',
    icon: 'VideoIcon',
    resource: 'video-stream',
    action: 'read',
  },
  {
    header: 'Занятия',
    icon: '',
    resource: 'teacher',
    action: 'read',
  },
  {
    header: 'Занятия',
    icon: '',
    resource: 'student',
    action: 'read',
  },
  {
    title: 'Уроки',
    route: 'cabinet.sessions',
    icon: 'ClockIcon',
    resource: 'video-stream',
    action: 'read',
  },
  {
    title: 'Домашние занятия',
    route: 'cabinet.hometasks',
    icon: 'BookIcon',
    resource: 'hometasks',
    action: 'read',
  },
  {
    title: 'Курсы',
    route: 'cabinet.interactive.courses',
    icon: 'BookOpenIcon',
    resource: 'interactive',
    action: 'read',
  },
  {
    header: 'Окружение',
    icon: '',
    resource: 'default',
    action: 'read',
  },
  {
    title: VueI18n.t('page.courses.title'),
    route: 'cabinet.courses',
    icon: 'BookIcon',
    resource: 'courses',
    action: 'read',
  },
  {
    title: 'Комментарии',
    route: 'cabinet.admin.step_comments',
    icon: 'MessageSquareIcon',
    resource: 'comments',
    action: 'read',
  },
  {
    title: VueI18n.t('page.exercises.title'),
    route: 'cabinet.exercises',
    icon: 'BookOpenIcon',
    resource: 'exercises',
    action: 'read',
  },
  {
    header: 'Пользователи',
    icon: '',
    resource: 'admin-teachers',
    action: 'read',
  },
  {
    title: 'Заявки',
    route: 'cabinet.admin.leads',
    icon: 'FilePlusIcon',
    resource: 'admin-leads',
    action: 'read',
  },
  {
    title: VueI18n.t('page.admin_teachers.title'),
    route: 'cabinet.admin.teachers',
    icon: 'UsersIcon',
    resource: 'admin-teachers',
    action: 'read',
  },
  {
    title: VueI18n.t('page.admin_students.title'),
    route: 'cabinet.admin.students',
    icon: 'UsersIcon',
    resource: 'admin-students',
    action: 'read',
  },
  {
    title: VueI18n.t('page.admin_subscriptions.nav_title'),
    route: 'cabinet.admin.subscriptions',
    icon: 'StarIcon',
    resource: 'admin-subscriptions',
    action: 'read',
  },
  {
    title: VueI18n.t('page.chats.title'),
    route: 'cabinet.admin.chat',
    icon: 'MessageSquareIcon',
    resource: 'admin-chats',
    action: 'read',
  },
  {
    header: 'Окружение',
    icon: '',
    resource: 'teacher',
    action: 'read',
  },
  {
    title: 'Заявки',
    route: 'cabinet.leads',
    icon: 'FilePlusIcon',
    resource: 'leads',
    action: 'read',
  },
  {
    title: VueI18n.t('page.teachers.title'),
    route: 'cabinet.teachers',
    icon: 'UsersIcon',
    resource: 'teachers',
    action: 'read',
  },
  {
    title: VueI18n.t('page.students.title'),
    route: 'cabinet.students',
    icon: 'UsersIcon',
    resource: 'students',
    action: 'read',
  },
  {
    title: 'Учебные программы',
    route: 'cabinet.study_programs',
    icon: 'CalendarIcon',
    resource: 'students',
    action: 'read',
  },
  {
    title: VueI18n.t('page.chats.title'),
    route: 'cabinet.chat',
    icon: 'MessageSquareIcon',
    resource: 'chats',
    action: 'read',
  },
  {
    title: 'Подбор репетитора',
    route: 'cabinet.lead.replies',
    icon: 'FilePlusIcon',
    resource: 'lead-replies',
    action: 'read',
  },
  {
    header: 'Система',
    icon: '',
    resource: 'default',
    action: 'read',
  },
  {
    title: VueI18n.t('page.profile.title'),
    route: 'cabinet.profile',
    icon: 'UserIcon',
    resource: 'profile',
    action: 'read',
  },
  // {
  //   title: VueI18n.t('page.subscriptions.title'),
  //   route: 'cabinet.subscriptions',
  //   icon: 'ShoppingCartIcon',
  //   resource: 'subscriptions',
  //   action: 'read',
  // },
  {
    title: 'Помощь',
    route: 'cabinet.help',
    icon: 'HelpCircleIcon',
    resource: 'profile',
    action: 'read',
  },
  {
    title: VueI18n.t('page.settings.title'),
    route: 'cabinet.settings',
    icon: 'SettingsIcon',
    resource: 'settings',
    action: 'read',
  },
]
