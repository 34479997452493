<template>
  <b-nav-item
    v-if="!dropdownItem"
    @click="skin = isDark ? 'light' : 'dark'"
  >
    <feather-icon
      size="21"
      :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
    />
  </b-nav-item>
  <b-dropdown-item
    v-else
    link-class="d-flex align-items-center"
    @click="skin = isDark ? 'light' : 'dark'"
  >
    <feather-icon
      size="23"
      class="mr-50"
      :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
    />
    <span v-if="!isDark">Ночной режим</span>
    <span v-else>Светлая тема</span>
  </b-dropdown-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { BNavItem, BDropdownItem } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
    BDropdownItem,
  },
  props: {
    dropdownItem: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
}
</script>
