<template>
  <div
    class="app-content content"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
      :style="isStreamRoute ? 'max-width: 100%' : ''"
    >
      <slot
        name="breadcrumb"
      >
        <!--        <app-sidebar-toggle-->
        <!--          v-if="$route.meta.hideHeader !== true || isStreamRoute"-->
        <!--          :fab="isStreamRoute"-->
        <!--        />-->
        <!--        <app-breadcrumb v-if="$route.meta.hideHeader !== true" />-->
      </slot>
      <div class="content-body">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
// import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'
// import AppSidebarToggle from '@core/layouts/components/AppSidebarToggle.vue'

export default {
  components: {
    // AppSidebarToggle,
    // AppBreadcrumb,
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition, contentWidth,
    }
  },
  computed: {
    isStreamRoute() {
      return ['cabinet.sessions.online', 'cabinet.room'].includes(this.$route.name)
    },
  },
}
</script>

<style>

</style>
